import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import AddNewBannerAd from "../../../components/BannerAd/AddNewBannerAd";
import Header from "../../../components/Header";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/dashapi`;

const BannerAdsGQLSchema = `
id
Image{
    FilePath
    Alt
}
CreatedAt
`;
const BannerAds = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoaderVisible, setLoaderVisibility] = useState("flex");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState("ADD");

  const [bannerAds, setBannerAds] = useState([]);
  const [dataCount, setDataCount] = useState();
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const addNewBannerAdHandler = (link, filepath, alt) => {
    addNewBannerAd(link, filepath, alt);
  };

  const ModalComponents = {
    ADD: (
      <AddNewBannerAd
        handleSubmit={addNewBannerAdHandler}
        closingModal={handleCloseModal}
      />
    ),
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNewBtnClick = () => {
    setModalOpen(true);
    setModalStatus("ADD");
  };

  const fetchDataCount = async () => {
    const res = await axios.post(
      API_URL,
      {
        query: `
            query{
                GetStaticNumberByKey(key:"Banner"){
                    Key
                    Value
                }
            }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );

    if (res.data.data.GetStaticNumberByKey) {
      setDataCount(res.data.data.GetStaticNumberByKey.Value);
    } else {
      setDataCount(0);
    }
  };
  const fetchData = async () => {
    setLoaderVisibility("flex");
    fetchDataCount();

    const res = await axios.post(
      API_URL,
      {
        query: `
              query {
                GetBannerAds{
                  ${BannerAdsGQLSchema}
                }
              }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.GetBannerAds) {
      setBannerAds(res.data.data.GetBannerAds);
      setLoaderVisibility("none");
    }
  };

  const addNewBannerAd = async (link, filepath, alt) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    AddNewBannerAd(link:"${link}",filepath:"${filepath}",alt:"${alt}"){
                        ${BannerAdsGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.AddNewBannerAd.id) {
      fetchData();
    }
  };

  const deleteBannerAd = async (bannerAd) => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                mutation{
                    DeleteBannerAd(id:"${bannerAd.id}")
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.DeleteBannerAd) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box m="20px">
      <Header title={`Banner Ads`} subtitle={`Add & Delete Banner Ads`} />
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>{ModalComponents[modalStatus]}</>
      </Modal>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={handleNewBtnClick}
          color="secondary"
        >
          Add New Banner Ad
        </Button>

        <Box
          sx={{
            display: isLoaderVisible === "flex" ? "flex" : "none",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" sx={{ mx: 2 }} />
          Fetching Data ... Please Wait
        </Box>
        <Box
          sx={{
            display: isLoaderVisible === "flex" ? "none" : "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            width: "70%",
          }}
        ></Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiTable-root": {
            borderRadius: "15px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiTableHead-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiTableBody-root": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTableCell-root": {
            borderBottom: "none",
            textAlign: "center",
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Preview</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bannerAds
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((ad) => (
                  <TableRow key={ad.id}>
                    <TableCell>{ad.Image.Alt}</TableCell>
                    <TableCell>{ad.CreatedAt}</TableCell>
                    <TableCell>
                      <IconButton>
                        <Link
                          to={`https://cloud.time4.games/${ad.Image.FilePath}`}
                          target="_blank"
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Link>
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => deleteBannerAd(ad)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default BannerAds;
