import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ImageIcon from "@mui/icons-material/Image";
import FlagIcon from "@mui/icons-material/Flag";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupsIcon from "@mui/icons-material/Groups";
import SportsIcon from "@mui/icons-material/Sports";
import GroupIcon from "@mui/icons-material/Group";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        position: "sticky",
        top: "0",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "bold" }}
                  color={colors.grey[100]}
                >
                  Time4Games
                </Typography>

                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}

            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h6" color="secondary">
                  Powered by ULDASH
                </Typography>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              key={1}
              title="Accounts"
              to="/Accounts"
              icon={<PersonIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            <Item
              key={2}
              title="Banner Ads"
              to="/BannerAds"
              icon={<ImageIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={23}
              title="Ads"
              to="/Ads"
              icon={<ImageIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={3}
              title="Countries"
              to="/Countries"
              icon={<FlagIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            <Item
              key={31}
              title={`Sports`}
              to={`/Sports`}
              icon={<SportsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={4}
              title="Competitions"
              to="/Competitions"
              icon={<EmojiEventsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={6}
              title={`Competitors`}
              to={`/Competitors`}
              icon={<GroupsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={7}
              title={`Soccer Sport Events`}
              to={`/SoccerSportEvents`}
              icon={<CompareArrowsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={7}
              title={`Soccer Players`}
              to={`/SoccerPlayer`}
              icon={<GroupIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              key={5}
              title="Score Prediction"
              to="/ScorePrediction"
              icon={<ScoreboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              key={5}
              title="Win Prediction"
              to="/WinPrediction"
              icon={<ScoreboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            <Item
              key={5}
              title="Top Competitions"
              to="/TopCompetitions"
              icon={<EmojiEventsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
