import React, { useEffect, useState } from "react";
import axios from "axios";
import { GLOBAL_API } from "../../../data/Links";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TablePagination,
} from "@mui/material";
import Header from "../../../components/Header";
import AddNewAds from "./AddNewAds";
import { getAuthentication } from "../../../auth";
import { Link } from "react-router-dom";

const API_URL = `${GLOBAL_API}/dashapi`;

const ADSGQLSCHEMA = `
id
Image
Title
Link
`;
const Ads = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoaderVisible, setLoaderVisibility] = useState("flex");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ads, setAds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addNewAdHandler = async (image, title, link) => {
    const res = await axios.post(
      API_URL,
      {
        query: `mutation{
            AddNewAds(image:"${image}",title:"${title}",link:"${link}"){
                ${ADSGQLSCHEMA}
            }
        }
        `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.AddNewAds.id) {
      fetchData();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const deleteAdHandler = async (id) => {
    const res = await axios.post(
      API_URL,
      {
        query: `mutation{
            DeleteAds(id:"${id}")
        }
        `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.DeleteAds) {
      fetchData();
    }
  };

  const fetchData = async () => {
    setLoaderVisibility("flex");
    const res = await axios.post(
      API_URL,
      {
        query: `
              query {
                GetAds(limit:${rowsPerPage},offset:${page}){
                  ${ADSGQLSCHEMA}
                }
              }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.GetAds) {
      setAds(res.data.data.GetAds);
      setLoaderVisibility("none");
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);
  return (
    <Box m="20px">
      <Header title={`Ads`} subtitle={`Add & Delete Ads`} />

      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddNewAds
          handleSubmit={addNewAdHandler}
          closingModal={handleCloseModal}
        />
      </Modal>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setModalOpen(true)}
          color="secondary"
        >
          Add New Ad
        </Button>

        <Box
          sx={{
            display: isLoaderVisible === "flex" ? "flex" : "none",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" sx={{ mx: 2 }} />
          Fetching Data ... Please Wait
        </Box>
      </Box>
      <Box>
        {ads.map((ad) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Link to={ad.Link}>
                <h6>{ad.Title}</h6>
                <img
                  style={{ width: "100%" }}
                  src={`https://cloud.time4.games/${ad.Image}`}
                  alt=""
                />
              </Link>
            </Box>
            <Box sx={{ width: "20%" }}>
              <Button
                onClick={() => deleteAdHandler(ad.id)}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
        component="div"
        count={100}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default Ads;
