import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  IconButton,
  TextareaAutosize,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  useMediaQuery,
} from "@mui/material";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import CloseIcon from "@mui/icons-material/Close";

import "react-image-crop/dist/ReactCrop.css";
import { useRef, useState } from "react";
import axios from "axios";
import { UPLOAD_API } from "../../../data/Links";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
const API_URL = UPLOAD_API;
const AddNewAds = ({ handleSubmit, closingModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(5 / 1);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [alt, setAlt] = useState("");
  const [link, setLink] = useState("");

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);

      console.log(imgSrc);
    }
  };
  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const HandleSubmitBtn = () => {
    handleSubmit(uploadedFileName, alt, link);
    closingModal();
  };

  const uploadFile = async () => {
    try {
      const canvas = document.createElement("canvas");
      const { x, y, width, height } = completedCrop;

      if (!completedCrop || !imgRef.current) {
        return;
      }

      const image = imgRef.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = width * scaleX;
      canvas.height = height * scaleY;

      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const croppedImage = await new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg", // or "image/png"
          0.8 // Quality parameter: 0 to 1, where 1 is highest quality
        );
      });

      const formData = new FormData();
      formData.append("image", croppedImage);

      console.log("image is ", formData.get("image"));

      const res = await axios.post(API_URL, formData, {
        headers: {
          token: `Bearer ${getAuthentication()}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 201) {
        setUploadedFileName(res.data.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: colors.primary[400],
        overflowY: "scroll",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={() => closingModal()} color="error">
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography
        variant="h3"
        color="secondary"
        sx={{
          textAlign: "center",
        }}
      >
        Ad
      </Typography>

      <Divider />
      <Box>
        <TextField
          sx={{
            mt: 3,
          }}
          fullWidth
          variant="filled"
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          label="Link ..."
          color="secondary"
        />
      </Box>
      <Box>
        <TextField
          sx={{
            mt: 3,
          }}
          fullWidth
          variant="filled"
          type="text"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
          label="Image Description ..."
          color="secondary"
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
        <Button
          sx={{ mx: 1 }}
          color="primary"
          variant="contained"
          component="label"
        >
          {" "}
          Browse Image{" "}
          <input type="file" accept="image/*" onChange={onSelectFile} hidden />
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="warning"
          variant="contained"
          component="label"
          onClick={uploadFile}
        >
          {" "}
          Upload Image
        </Button>
      </Box>
      <Box sx={{ width: "60%", mx: "auto", mt: 4 }}>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </Box>
      {imgSrc !== "" ? (
        <Typography
          sx={{ textAlign: "center" }}
          color={uploadedFileName !== "" ? "secondary" : "error"}
        >
          {uploadedFileName !== ""
            ? "Image Uploaded Successfully"
            : "Pending !!"}
        </Typography>
      ) : (
        <></>
      )}

      {uploadedFileName !== "" ? (
        <Button
          onClick={HandleSubmitBtn}
          sx={{ mt: 2 }}
          variant="contained"
          color="secondary"
        >
          Add New Ad
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AddNewAds;
